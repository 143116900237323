import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FormatShortDateValueConverter } from 'resources/value-converters/format-short-date';
import { MyHttpApi, NKOrderStatus, PublicOrderStatusResponse } from 'utils/api';
import { Notify } from 'utils/notify';
import { TranslationUtil } from 'utils/translation-util';

export type UIOrderStatus = NKOrderStatus | "AT_PREMISE";

function isTerminal(orderStatus: NKOrderStatus) {
  return orderStatus === "DELETED" || orderStatus === "DELIVERED";
}

interface UIOrder extends PublicOrderStatusResponse {
  uiStatus: UIOrderStatus;
}

interface Vat {
  vatPercent: number;
  total: number;
  zeroVatTotal: number;
}

interface PickupAddress {
  name: string;
  name2?: string;
  city: string;
  zip: string;
}

@autoinject
export class OrderShow {
  private orderId = 0;
  private order?: UIOrder;
  private storeAtPremiseInfo = "";
  private refreshTimer?: NodeJS.Timer;
  private pickupAddress?: PickupAddress;
  private orderVat: Vat[] = [];
	private activityTimeout?: any;
  constructor(private router: Router, private client: MyHttpApi, private notify: Notify, private i18n: I18N, private translationUtil: TranslationUtil) {
  }


  attached() {
		if(this.notify.store?.store.redirectToFrontPageTime) {
			document.addEventListener("mousemove", this.resetActive.bind(this));
			document.addEventListener("touchmove", this.resetActive.bind(this));
			this.startResetSessionTimer();		
		}
  }

	startResetSessionTimer() {
		if(this.notify.store?.store.redirectToFrontPageTime) {
			this.activityTimeout = setTimeout(async () => {
				await this.client.sessionLogout();
        this.client.session = undefined;
				localStorage.removeItem("sessionProducts");
				localStorage.removeItem("lastOrderChangeTimestamp");
				this.router.navigateToRoute("/");
			}, this.notify.store?.store.redirectToFrontPageTime * 1000);
		}		
	}

	resetActive() {
		if(this.notify.store?.store.redirectToFrontPageTime) {
			clearTimeout(this.activityTimeout);
			this.startResetSessionTimer();
		}
	}

  detached() {
		if(this.notify.store?.store.redirectToFrontPageTime) {
			document.removeEventListener("mousemove", this.resetActive.bind(this));
			document.removeEventListener("touchmove", this.resetActive.bind(this));
		}
  }

  async activate(params: any) {
    this.orderId = parseInt(params.id);
    this.refreshTimer = setInterval(() => this.refreshOrderStatus(), 15000);
    await this.refreshOrderStatus();

    if (this.order) {
      if (this.order.orderStatus === 'NEW') {
        localStorage.removeItem("sessionProducts");
        localStorage.removeItem("lastOrderChangeTimestamp");
      }
      this.storeAtPremiseInfo = this.translationUtil.localized(this.order.store, "atPremise", this.lang);
      if (this.notify.store?.store?.id !== this.order.store.id) {
        this.notify.store = await this.client.publicStoreById({ id: this.order.store.id });
      }
      this.pickupAddress = {
        name: this.order.businessUnit.addressName,
        name2: this.order.businessUnit.addressName2,
        city: this.order.businessUnit.city,
        zip: this.order.businessUnit.zip,
      };

      let tmp: { [key: number]: Vat; } = {};
      for (let row of this.order.orderRowList) {
        if (row.orderRow.productType === 'RECIPE') {
          continue;
        }
        if (!tmp[row.orderRow.vatPercent]) {
          tmp[row.orderRow.vatPercent] = {
            vatPercent: row.orderRow.vatPercent,
            total: 0,
            zeroVatTotal: 0,
          };
        }
        let vat = tmp[row.orderRow.vatPercent];
        vat.total += row.orderRow.total;
        let vatValue = Math.round(row.orderRow.total * row.orderRow.vatPercent / (100 + row.orderRow.vatPercent) * 100 + 0.2e-4) / 100;
        vat.zeroVatTotal += row.orderRow.total - vatValue;
      }

      this.orderVat = Object.values(tmp);
      this.orderVat.sort((a, b) => a.vatPercent - b.vatPercent);
    }
  }

  deactivate() {
    this.stopRefreshOrderStatus();
  }

  stopRefreshOrderStatus() {
    if (this.refreshTimer) {
      window.clearInterval(this.refreshTimer);
      this.refreshTimer = undefined;
    }
  }

  async refreshOrderStatus() {
    let order = await this.client.publicOrderStatus({ orderId: this.orderId });
    let uiStatus: UIOrderStatus = order.orderStatus;
    if (order.order.deliveryMethod === 'ON_SITE') {
      const transformStatuses: NKOrderStatus[] = ['NEW', 'ACCEPTED', 'PREPARED', 'PICKUP', 'DELIVERED'];
      if (transformStatuses.includes(order.orderStatus)) {
        uiStatus = 'AT_PREMISE';
      }
    }
    this.order = {
      ...order,
      uiStatus,
    };
    if (isTerminal(order.orderStatus)) {
      this.stopRefreshOrderStatus();
    }
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }

  localizeBrand(string: string | undefined, _language: string) {
    if (!string) {
      return "";
    } else {
      return string.replace(/\{([^}]+)\}/g, (_match, token) => this.i18n.tr(token, {}));
    }
  }

  @computedFrom("store")
  get logoUrl() {
    if (!this.order) {
      return "";
    }
    return this.client.publicLogoImageUrl({
      id: this.order.businessUnitBrand.businessUnitId,
      modifyTime: this.order.businessUnitBrand.modifyTime,
    });
  }

  productImage(id: number) {
    if (!this.order) {
      return "";
    }
    /* N.B. should really be product's modifytime,
     * but order's desired ready time should also allow valid caching. */
    return this.client.publicProductImageUrl({ id, modifyTime: this.order.order.desiredReadyTime });
  }

  async payHosted() {
    if (!this.order) {
      return;
    }
    let forward = await this.client.publicPaymentHosted({ orderId: this.order.order.id });
    this.notify.setForward(forward);
  }

  @computedFrom("order.order.desiredReadyTime")
  get orderReady() {
    if (!this.order) {
      return;
    }

    if (isTerminal(this.order.orderStatus)) {
      return;
    }

    let now = new Date();
    let deliveryTime = this.order.order.desiredReadyTime;
    if (deliveryTime < now) {
      /* overtime! -- no point to show anything */
      return;
    }

    let minutesToDelivery = Math.floor((deliveryTime.getTime() - now.getTime()) / 60 / 1000);
    let hours = Math.floor(minutesToDelivery / 60);
    let days = Math.floor(hours / 24);
    let minutes = minutesToDelivery % 60;

    let timeString: string;
    if (minutesToDelivery < 60) {
      timeString = minutes + " min";
    } else if (hours < 24) {
      timeString = hours + " h " + minutes + " min";
    } else {
      timeString = days + " pv";
    }
    if (now.getDate() != deliveryTime.getDate()) {
      timeString += ", " + new FormatShortDateValueConverter().toView(deliveryTime);
    } else if (hours > 0) {
      timeString += ", " + new FormatShortDateValueConverter().toView(deliveryTime, "noDate");
    }
    return timeString;
  }

  @computedFrom("order")
  get totalQuantity() {
    if (!this.order) {
      return 0;
    }
    return this.order.orderRowList.filter(o => !o.orderRow.parentId).map(o => o.orderRow.quantity).reduce((a, b) => a + b, 0);
  }

  @computedFrom("order")
  get pdfUrl() {
    if (!this.order?.order) {
      return "";
    }
    return this.client.publicOrderPdfUrl({ id: this.order.order.id });
  }
}
