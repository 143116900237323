import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { AccountRequestSetPasswordResponse, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class ForgotPasswordCustomElement {
  @bindable({ defaultBindingMode: bindingMode.toView }) submit = (_req: { value: AccountRequestSetPasswordResponse }) => { /* placeholder */ };

  username = "";

  constructor(private client: MyHttpApi, private notify: Notify) {
  }

  async change() {
    if (!this.username) {
      return;
    }
    let result = await this.client.accountRequestSetPassword({ email: this.username });
    if (result.username) {
      this.notify.info("forgotPassword.emailSent", {});
      this.submit({ value: result });
    } else {
      this.notify.info("forgotPassword.noSuchUser", {});
    }
  }
}
