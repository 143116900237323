export default {
  AddressType: {
    HOME: "Home",
    WORK: "Work",
    OTHER: "Other"
  },
  AuthenticationType: {
    EMAIL: "Email",
    PHONE: "SMS",
  },
  DayOfWeek: {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
  },
  DayOfWeekShort: {
    MONDAY: "Mon",
    TUESDAY: "Tue",
    WEDNESDAY: "Wed",
    THURSDAY: "Thu",
    FRIDAY: "Fri",
    SATURDAY: "Sat",
    SUNDAY: "Sun",
  },
  DeliveryMethod: {
    EMAIL: "Email",
    FETCH: "Pickup",
    ON_SITE: "On site",
  },
  HumanOrderStatus: {
    NEW: "Order paid succesfully. Thank you for your order.",
    AT_PREMISE: "Your order has been received and our staff will delivered it to you as soon as possible.",
    ACCEPTED: "Order has been accepted",
    PAYMENT: "Order requires payment in advance",
    PAYMENT_IN_PROCESS: "Processing payment",
    PREPARED: "Order is being prepared",
    PICKUP: "Order is ready for pickup on noudettavissa",
    DELIVERED: "Order has been delivered",
    DELETED: "Order has been cancelled"
  },
  Language: {
    FI: "Suomi",
    SV: "Svenska",
    EN: "English",
  },
  OrderStatus: {
    NEW: "New",
    ACCEPTED: "Waiting for confirmation",
    PAYMENT: "Waiting for payment",
    PAYMENT_IN_PROCESS: "Processing payment",
    PREPARED: "Being prepared",
    PICKUP: "Ready for pickup",
    DELIVERED: "Order is done",
    DELETED: "Order has been cancelled"
  },
  PaymentRefundStatus: {
    Success: "Payment has been refunded.",
    Failed: "Payment has not been refunded. Please contact the shop!",
  },
  PaymentType: {
    OnSite: "Pay on pickup",
    PrePaid: "Prepaid order",
  },
  WebPaymentType: {
    BLUE_COMMERCE: "Payment via Verifone",
    VIVA_WALLET: "Payment via Viva wallet"
  },
  account: {
    code: "Code, which your received",
    confirm: "Confirm",
  },
  address: {
    address: "Address",
    city: "City",
    custom: "Vaihtoehtoinen toimitusosoite",
    deliveryAddress: "Delivery address",
    pickupAddress: "Pick up address",
    gateCode: "Door code",
    home: "Home",
    invalidDeliveryAddress: "Invalid delivery address",
    name: "Name",
    name2: "Name 2. row",
    new: "Add new address",
    streetAddress: "Street address",
    type: "Tyep",
    zip: "ZIP",
  },
  businessUnit: {
    addressName: "Address",
    unitOfficialIdentifier: "Business ID",
    unitOfficialName: "Company official name",
  },
  common: {
    and: "and",
    authenticationType: "Authentication type",
    back: "Back",
    clear: "Clear",
    continue: "Continue",
    copy: "Copy",
    delete: "Delete",
    email: "Email",
    empty: "No selection",
    firstName: "First name",
    language: "Language",
    lastName: "Last name",
    modify: "Modify",
    name: "Name",
    notSame: "The passwords do not match",
    ok: "Ok",
    oldPassword: "Old password",
    optional: "Optional",
    password: "Password",
    password2: "Password (again)",
    phone: "Phonenumber",
    required: "Required",
    save: "Save",
    saved: "Saved",
    searchButton: "Search",
    select: "Select",
    showAll: "Show all",
    status: "Status",
    time: "Time",
  },
  error: {
    extra: {
      invalidation: "Extra selections are not valid",
      mandatory: "At least one extra selection is mandatory",
    }
  },
  extra: {
    allSelected: "All choices made",
    selectMore: "Choose {{amount}} more",
  },
  forgotPassword: {
    body: "Please provide your email or phone number. We will send you a message to reset your password.",
    closeBody: "You can now close this window/tab.",
    closeHeading: "Password has been set",
    emailSent: "Message has been sent to given address.",
    noSuchUser: "No such user.",
    title: "Reset your password",
  },
  gdpr: {
    cardCountryCode: "Card country code",
    cardIssuingBank: "Card issuing bank",
    cardNumber: "Bank card",
    cardUniqueReference: "Card unique reference",
    download: "Download information in JSON format",
    emailSent: "Information request has been sent.",
    noMessageOrsession: "Can't load information. Please check link.",
    sendRequest: "Send information request",
    sessionCreateTime: "Login time",
    splash: "Request a link to your data with the email address used to sign up for the service.",
    storeName: "Store name",
    terminate: "Terminate customer relationship",
    terminateConfirm: "Are you sure you want to terminate customer relationship? After termination you are not able to access any orders you have made.",
    transactionType: "Transaction type",
    vivaWalletInfos: "Payment transaction information",
  },
  giftCard: {
    title: "Gift card order",
  },
  google: {
    analyticsText: "We use cookies for tracking the quality of user experience. If you allow use of cookies, please click 'Yes'",
    yes: "Yes",
    no: "No",
  },
  login: {
    emailOrPhone: "Email or phonenumber",
    emailOrPhonePlaceholder: "name@company.fi tai 04012345678",
    forgotPassword: "Forgot my password...",
    login: "Login",
    title: "Login TuloPOS-webstore",
  },
  menu: {
    accountEdit: "My information",
    gdpr: "GDPR information request",
    login: "Login",
    logout: "Logout",
    orders: "Orders",
    storeList: "Store",
  },
  order: {
    amount: "Amount",
    backToStore: "Return to store",
    closed: "Closed",
    deliveryDate: "Delivery date",
    deliveryTime: "Delivery time",
    deliveryTimeNoLongerValid: "Order can no longer be made for the delivery time you chose.",
    deliveryMethod: "Delivery method",
    desiredReadyTime: "Requested time",
    discountCode: "Discount code",
    discountEmail: "Your email",
    discountHeader: "Input your email or discount code for your discounts.",
    discountGroupName: "Your discount group {{discountGroup}}",
    downloadInvoice: "Download invoice",
    extras: "Additional options",
    freeText: "Message to store",
    mandatoryPrivacy: "I have read and accept",
    mandatoryTerms: "I have read and accept the terms of order",
    minimumOrderAmount: "Minimum order amount: {{amount}} €",
    order: "Order",
    open: "Open",
    outOfStock: "Temporarily out of stock",
    paid: "Paid for at",
    pay: "Pay for order",
    payment: "Payment",
    privacyPolicy: "the privacy statement",
    restoreOrder: "You have an unfinished order in our store, would you like to continue that order?",
    sendOrder: "Order",
    selectDeliverAddress: "Choose delivery address",
    selectDeliveryDate: "Choose delivery time",
    selectedDeliverAddress: "Chosen delivery address",
    shopIsClosed: "Shop is closed.",
    shopIsClosedButOpensInFuture: "Shop is closed now, but you can order on other days by choosing another date and time.",
    shopIsClosedByDefaultAndNoDeliveryDate: "Shop is open only during specific occasions. There are no occasions in near future.",
    shopIsClosedOnSite: "The shop is open when the restaurant is open.",
    shopIsClosedTemporarily: "We have had to temporarily close our net store. You can order on other days by choosing another date and time.",
    tableNumber: "Table",
    tableNumberHelp: "Please input your table's number so that personnel can deliver your order to this table.",
    termsAndConditions: "terms and conditions",
    total: "Total",
    vat: "VAT",
    vatPercent: "VAT-%",
    zeroVatTotal: "Total (VAT 0%)",
  },
  register: {
    passwordMismatch: "Passwords do not match",
    passwordPlaceholder: "••••••••",
  },
  server: {
    blueCommerceDown: "Payment processor could not be contacted. Please try again in a moment.",
    emailExists: "Account has already been registered. For security reasons, we sent a message where we advice to set a new password and log on with it.",
    gdprByMessageTokenInvalid: "The link is either expired or erronous",
    invalidUsernameOrPassword: "Wrong username or password",
    outOfStock: "Product or extra choice is not available.",
    passwordNotComplexEnough: "Password is not complex enough. Password has to be at least 8 characters long, and needs to have at least one lower case and one uppercase letter.",
    vivaWalletFailure: "Payment processor could not handle the request {{statusCode}}",
    wrongLogin: "Account does not have access",
  },
  store: {
    addMoreProduct: "+",
    addProduct: "Add",
    categorySearch: "Search products by category",
    changePortion: "Change portion size",
    copyProduct: "Copy product (for other selection)",
    closed: "Closed",
    discountGroupsFound: "Discount activated.",
    discountGroupsNotFound: "Discount code was not found.",
    discountPercent: "Discount",
    fetchAddress: "Pickup address",
    fullPrice: "Full price",
    noProductsFound: "No products were found with search “{{search}}”.",
    outOfInventory: "There are only {{quantity}} pieces left of this product",
    paymentFailure: "Payment transaction failed",
    paymentFailureExplanation: "Your payment transaction failed, you can try payment again from button below.",
    price: "Price",
    productCategoryCanNotBeSold: "Product cateogry is not available during the selected time",
    productCanBeSoldInFuture: "Product becomes available after {{startDate}}. Select date from above.",
    productCanNotBeSold: "Product is not available during the selected time",
    productCategorySalePeriod: "Sale period",
    productNotInSale: "Product can not be sold",
    productSearch: "Search products by name",
    productSearchInputPlaceholder: "Search products",
    productsLeft: " left!",
    removeProduct: "\u2212",
    search: "Search",
    storeIsClosed: "Store is closed",
    quantity: "Amount",
    unitPrice: "Unit Price",
    value: "Value (€)",
  },
};
