import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { TranslationUtil } from 'utils/translation-util';

@autoinject
export class ButtonChoiceCustomElement<T> {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value?: T;
  @bindable({ defaultBindingMode: bindingMode.toView }) content: { id: T, name: string}[] = [];
  @bindable({ defaultBindingMode: bindingMode.toView }) empty?: string;

  constructor(private i18n: I18N, private translationUtil: TranslationUtil) {
  }

  setOption(id: T) {
    this.value = id;
  }

  @computedFrom("i18n.i18next.language")
  get lang() {
    return this.i18n.i18next.language;
  }
}
