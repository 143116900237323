import fi from './fi';
import sv from './sv';
import en from './en';
import { ModuleType } from 'i18next';

export class LocaleBackend {
  public type: ModuleType = "backend";

  init() {
    /* nothing */
  }

  read(language: string, _namespace: string, callback: (error: string | undefined, data: {}) => void) {
    if (language == "fi") {
      callback(undefined, fi);
      return;
    }
    if (language == "sv") {
      callback(undefined, sv);
      return;
    }
    callback(undefined, en);
  }

}