import { bindable, bindingMode } from 'aurelia-framework';

export class InputSearchCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) search?: string;
  @bindable() id = "productSearchField";

  clearSearch() {
    this.search = "";
    document.getElementById(this.id)?.focus()
  }
}
