export default {
  AddressType: {
    HOME: "Koti",
    WORK: "Työ",
    OTHER: "Muu"
  },
  AuthenticationType: {
    EMAIL: "Sähköposti",
    PHONE: "SMS",
  },
  DayOfWeek: {
    MONDAY: "Maanantai",
    TUESDAY: "Tiistai",
    WEDNESDAY: "Keskiviikko",
    THURSDAY: "Torstai",
    FRIDAY: "Perjantai",
    SATURDAY: "Lauantai",
    SUNDAY: "Sunnuntai",
  },
  DayOfWeekShort: {
    MONDAY: "Ma",
    TUESDAY: "Ti",
    WEDNESDAY: "Ke",
    THURSDAY: "To",
    FRIDAY: "Pe",
    SATURDAY: "La",
    SUNDAY: "Su",
  },
  DeliveryMethod: {
    EMAIL: "Sähköposti",
    FETCH: "Nouto",
    ON_SITE: "Paikanpäällä",
  },
  HumanOrderStatus: {
    NEW: 'Tilaus maksettu onnistuneesti. Kiitos tilauksesta.',
    AT_PREMISE: "Tilauksesi on otettu vastaan ja henkilökuntamme toimittaa sen sinulle pian!",
    ACCEPTED: "Tilaus on vastaanotettu",
    PAYMENT: "Tilauksen maksu etukäteen",
    PAYMENT_IN_PROCESS: "Maksu käsittelyssä",
    PREPARED: "Tilausta valmistetaan",
    PICKUP: "Tilaus on noudettavissa",
    DELIVERED: "Tilaus on toimitettu",
    DELETED: "Ravintola hylännyt tilauksen"
  },
  Language: {
    FI: "Suomi",
    SV: "Svenska",
    EN: "English",
  },
  OrderStatus: {
    NEW: 'Uusi',
    ACCEPTED: "Odottaa vahvistusta",
    PREPARED: "Valmistuksessa",
    PAYMENT: "Odottaa maksua",
    PAYMENT_IN_PROCESS: "Maksu käsittelyssä",
    PICKUP: "Noudettavissa",
    DELIVERED: "Valmis",
    DELETED: "Ravintola hylännyt tilauksen"
  },
  PaymentRefundStatus: {
    Success: "Maksu on hyvitetty",
    Failed: "Maksua ei ole hyvitetty, ota yhteyttä kauppaan!",
  },
  PaymentType: {
    OnSite: "Maksu paikanpäällä",
    PrePaid: "Maksu etukäteen",
  },
  WebPaymentType: {
    BLUE_COMMERCE: "Verifonen verkkomaksu",
    VIVA_WALLET: "Viva wallet verkkomaksu"
  },
  account: {
    code: "Koodi, jonka sait viestillä",
    confirm: "Vahvista",
  },
  address: {
    address: "Osoitetiedot",
    city: "Postitoimipaikka",
    custom: "Vaihtoehtoinen toimitusosoite",
    deliveryAddress: "Toimitusosoite",
    pickupAddress: "Nouto-osoite",
    gateCode: "Ovikoodi",
    home: "Koti",
    invalidDeliveryAddress: "Virheellinen toimitusosoite",
    name: "Nimi",
    name2: "Nimen 2. rivi",
    new: "Lisää uusi osoite",
    streetAddress: "Katuosoite",
    type: "Tyyppi",
    zip: "Postinumero",
  },
  businessUnit: {
    addressName: "Osoite",
    unitOfficialIdentifier: "Y-Tunnus",
    unitOfficialName: "Yrityksen virallinen nimi",
  },
  common: {
    and: "ja",
    authenticationType: "Tunnistautumistapa",
    back: "Takaisin",
    clear: "Tyhjennä",
    continue: "Jatka",
    copy: "Kopio",
    delete: "Poista",
    email: "Sähköpostiosoite",
    empty: "Ei valintaa",
    firstName: "Etunimi",
    language: "Kieli",
    lastName: "Sukunimi",
    modify: "Muokkaa",
    name: "Nimi",
    notSame: "Salasanat eivät täsmää",
    ok: "Ok",
    oldPassword: "Vanha salasana",
    optional: "Valinnainen",
    password: "Salasana",
    password2: "Salasana (uudelleen)",
    phone: "Puhelinnumero",
    required: "Pakollinen",
    save: "Tallenna",
    saved: "Tallennettu",
    searchButton: "Hae",
    select: "Valitse",
    showAll: "Näytä kaikki",
    status: "Tila",
    time: "Aika",
  },
  error: {
    extra: {
      invalidation: 'Lisävalinnat eivät ole oikein',
      mandatory: 'Ainakin yksi valinta on pakollinen',
    }
  },
  extra: {
    allSelected: "Kaikki valinnat tehty",
    selectMore: "Valitse vielä {{amount}}",
  },
  forgotPassword: {
    body: "Syötä sähköpostiosoitteesi. Lähetämme sinne linkin, jonka kautta voit asettaa uuden salasanan.",
    closeBody: "Voit nyt sulkea tämän ikkunan/tabin.",
    closeHeading: "Salasana on asetettu",
    emailSent: "Viesti lähetetty osoitteeseen",
    noSuchUser: "Käyttäjätunnusta ei löytynyt",
    title: "Salasanan asettaminen",
  },
  gdpr: {
    cardCountryCode: "Pankkikortin maakoodi",
    cardIssuingBank: "Kortin myöntänyt pankki",
    cardNumber: "Pankkiportti",
    cardUniqueReference: "Kortin uniikki ID",
    download: "Lataa tiedot JSON-muodossa",
    emailSent: "Tietopyyntö lähetetty.",
    noMessageOrsession: "Tietoja ei voitu ladata, tarkista linkki.",
    sendRequest: "Lähetä tietopyyntö",
    sessionCreateTime: "Kirjautumisaika",
    splash: "Syötä käyttämäsi sähköposti ja saat linkin tietojesi koostesivulle.",
    storeName: "Kaupan nimi",
    terminate: "Lopeta asiakkuus",
    terminateConfirm: "Haluatko varmasti lopettaa asiakkuuden? Et pääse lopettamisen jälkeen enää käsiksi tekemiisi tilauksiisi.",
    transactionType: "Tapahtuman tyyppi",
    vivaWalletInfos: "Maksutapahtumatietoja",
  },
  giftCard: {
    title: "Lahjakorttitilaus",
  },
  google: {
    analyticsText: "Käytämme evästeitä palvelun laadun seurantaan. Jos hyväksyt evästeiden käytön, paina kyllä.",
    yes: "Kyllä",
    no: "Ei",
  },
  login: {
    emailOrPhone: "Sähköpostiosoite tai puhelinnumero",
    emailOrPhonePlaceholder: "etu.suku@firma.fi tai 04012345678",
    forgotPassword: "Unohdin salasanani...",
    login: "Kirjaudu",
    title: "Kirjautuminen TuloPOS-nettikauppaan",
  },
  menu: {
    accountEdit: "Omat tiedot",
    gdpr: "GDPR-tietopyyntö",
    login: "Kirjaudu",
    logout: "Kirjaudu ulos",
    orders: "Tilaukset",
    storeList: "Kauppa",
  },
  order: {
    amount: "Määrä",
    backToStore: "Palaa kauppaan",
    closed: "Suljettu",
    deliveryDate: "Noutopäivä",
    deliveryTime: "Noutohetki",
    deliveryTimeNoLongerValid: "Tilausta ei voi enään tehdä valitsemallesi ajalle.",
    deliveryMethod: "Toimitustapa",
    desiredReadyTime: "Noutoaika",
    discountCode: "Alennuskoodi",
    discountEmail: "Sähköpostisi",
    discountHeader: "Syötä sähköposti tai alennuskoodi aktivoidaksesi alennukseksi.",
    discountGroupName: "Sinun alennusryhmäsi {{discountGroup}}",
    downloadInvoice: "Lataa lasku",
    extras: "Lisävalinnat",
    freeText: "Viesti kaupalle",
    mandatoryPrivacy: "Olen lukenut ja hyväksyn",
    mandatoryTerms: "Olen lukenut ja hyväksyn tilausehdot",
    minimumOrderAmount: "Minimitilaus on: {{amount}} €",
    order: "Tilaus",
    open: "Avoinna",
    outOfStock: "Tilapäisesti loppu",
    paid: "Maksettu",
    pay: "Maksa tilaus",
    payment: "Maksu",
    privacyPolicy: "tietosuojalausekkeen",
    restoreOrder: "Kaupassa on tallessa keskeneräinen tilauksesi, haluatko jatkaa tilausta",
    sendOrder: "Tilaa",
    selectDeliverAddress: "Valitse toimitusosoite",
    selectDeliveryDate: "Valitse noutoaika",
    selectedDeliverAddress: "Valittu toimitusosoite",
    shopIsClosed: "Kauppa on suljettu.",
    shopIsClosedButOpensInFuture: "Kauppa on suljettu. Voit tehdä tilauksen muille päiville valitsemalla ajan ylhäältä.",
    shopIsClosedByDefaultAndNoDeliveryDate: "Kauppa on käytettävissä vain tilaisuuksien yhteydessä, joita ei ole lähipäivinä.",
    shopIsClosedOnSite: "Kauppamme on auki ravintolan aukiollessa.",
    shopIsClosedTemporarily: "Olemme joutuneet sulkemaan kauppamme tilapäisesti. Voit tilata muille päivälle valitsemalla ajan ylhäältä.",
    tableNumber: "Pöytä",
    tableNumberHelp: "Syötä pöytänumerosi, jotta henkilökunta voi toimittaa tilauksesi tähän pöytään.",
    termsAndConditions: "käyttöehdot",
    total: "Yhteensä (+ ALV)",
    vat: "ALV",
    vatPercent: "ALV-%",
    zeroVatTotal: "Yhteensä (ALV 0%)",
  },
  register: {
    passwordMismatch: "Salasanat eivät täsmää",
    passwordPlaceholder: "••••••••",
  },
  server: {
    blueCommerceDown: "Maksuoperaattoriin ei saatu yhteyttä. Yritä hetken kuluttua uudestaan.",
    emailExists: "Tämä käyttäjätunnus on jo rekisteröity. Turvallisuussyistä lähetimme viestin, jossa pyydämme asettamaan uuden salasanan ja kirjautumaan sillä järjestelmään.",
    gdprByMessageTokenInvalid: "Linkki on vanhentunut tai virheellinen",
    invalidUsernameOrPassword: "Virhe käyttäjätunnuksessa tai salasanassa",
    outOfStock: "Virhe tuotetta tai valintaa ei ole saatavilla.",
    passwordNotComplexEnough: "Salasanan on oltava monimutkaisempi. Pituuden pitää olla vähintään 8 merkkiä, ja siinä pitää olla sekä isoja että pieniä kirjaimia.",
    vivaWalletFailure: "Maksuoperaattori ei voinut käsitellä pyyntöä {{statusCode}}",
    wrongLogin: "Tunnuksella ei ole käyttöoikeutta tietoon",
  },
  store: {
    addMoreProduct: "+",
    addProduct: "Lisää",
    categorySearch: "Hae tuotteita kategorioittain",
    changePortion: "Vaihda annoskokoa",
    copyProduct: "Kopioi tuote (muita valintoja varten)",
    closed: "Suljettu",
    discountGroupsFound: "Alennus aktivoitu.",
    discountGroupsNotFound: "Alennusta ei löytynyt.",
    discountPercent: "Alennus",
    fetchAddress: "Nouto-osoite",
    fullPrice: "Täysi hinta",
    noProductsFound: "Tuotteita ei löytynyt haulla “{{search}}”.",
    outOfInventory: "Tuotetta on jäljellä vain {{quantity}} kappaletta",
    outOfStock: "Tuotetta ei ole jäljellä varastossa",
    paymentFailure: "Maksu epäonnistui",
    paymentFailureExplanation: "Tilauksen maksaminen epäonnistui, voit yrittää maksua uudelleen alla olevasta napista.",
    price: "Hinta",
    productCategoryCanNotBeSold: "Tuotekategorian tuotteita ei voi myydä valittuna aikana",
    productCanBeSoldInFuture: "Tuotetta ei voi valita, tulee myyntiin {{startDate}}. Valitse noutoaika ylhäältä.",
    productCanNotBeSold: "Tuotetta ei voi myydä valittuna aikana",
    productCategorySalePeriod: "Myyntiaika",
    productNotInSale: "Tuotetta ei voi myydä",
    productSearch: "Hae tuotteita nimellä",
    productSearchInputPlaceholder: "Hae tuotteita",
    productsLeft: " jäljellä!",
    removeProduct: "\u2212",
    search: "Haku",
    storeIsClosed: "Kauppa on kiinni.",
    quantity: "Määrä",
    unitPrice: "Yksikköhinta",
    value: "Arvo (€)",
  },
};
