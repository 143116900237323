import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class GdprRequest {
  private jsonDownloadUrl = "";

  constructor(private notify: Notify, private api: MyHttpApi, private router: Router) {
  }

  async activate(params: { m: string; }) {
    const session = await this.api.publicGdprLogin({ m: params.m });
    this.api.session = session;
    this.router.navigateToRoute("gdpr/data");
  }
}
