import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { MyHttpApi } from "utils/api";

@autoinject
export class SwissArmyLoginCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) firstName?: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) lastName?: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) email?: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) phone?: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) emailChanged = () => { /* default, nothing */ };

    constructor(private client: MyHttpApi) {
    }
}
