import { autoinject, bindable, bindingMode } from 'aurelia-framework';

@autoinject
export class ModalCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) visible = false;
  @bindable({ defaultBindingMode: bindingMode.toView }) contentClass?: string;
  
  constructor(private element: Element) {
  }

  hide(event: Event | undefined) {
    if (!event) {
      this.visible = false;
      return;
    }

    if (event.target instanceof HTMLDivElement) {
      if (event.target.className === "modal") {
        this.visible = false;
        return;
      }
    }
  }

  attached() {
    this.autofocus();
  }

  visibleChanged() {
    setTimeout(() => this.autofocus(), 1);
  }

  autofocus() {
    let element = this.element.querySelector<HTMLElement>("*[autofocus]");
    if (element) {
      element.focus();
    }
  }
}
