import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class GdprRequest {
  private email = "";

  constructor(private notify: Notify, private api: MyHttpApi, private router: Router) {
  }

  async requestEmail() {
    if (!this.email) {
      return;
    }
    await this.api.publicGdprSendLink({ email: this.email });
    this.notify.info("gdpr.emailSent");
    this.router.navigateToRoute("/");
  }
}
