import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi } from 'utils/api';

@autoinject
export class SessionLoginCustomElement {
  @bindable({ defaultBindingMode: bindingMode.toView }) login = () => {/* placeholder */ };
  @bindable({ defaultBindingMode: bindingMode.toView }) forgotPassword = () => {/* placeholder */ };

  username = "";
  password = "";

  constructor(private client: MyHttpApi, private router: Router) {
  }

  attached() {
    let field = <HTMLInputElement>document.querySelector("input[name='username']");
    field && field.focus();
  }

  async submit() {
    let session = await this.client.sessionLogin({ username: this.username, password: this.password });
    this.client.session = session;
    this.login();
  }
}
