import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class StoreListIndex {
  private error = false;
  
  constructor(private client: MyHttpApi, private notify: Notify, private router: Router) {
  }

  async activate(params: {}) {
    let store = this.notify.store;
    if (!store) {
      this.error = true;
      return;
    }
    if (store.store.storeType == "GIFT_CARD") {
        this.router.navigateToRoute("gift-shop", params);
    } else {
      this.router.navigateToRoute("shop", params);
    }
  }
}
