import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { BusinessUnit } from 'utils/api';

@autoinject
export class TermsAndConditionsCustomElement {
  @bindable({ defaultBindingMode: bindingMode.toView }) lang = "fi" ;
  @bindable({ defaultBindingMode: bindingMode.toView }) businessUnit?: BusinessUnit;
  @bindable({ defaultBindingMode: bindingMode.toView }) ok = () => { /* default implementation */ };
  
  constructor(private element: Element) {
  }
}
