import { autoinject } from 'aurelia-framework';
import { MyHttpApi, PublicAccountOrdersResponse } from 'utils/api';

@autoinject
export class OrderList {
  private paorList: PublicAccountOrdersResponse[] = [];

  constructor(private client: MyHttpApi) {
  }

  async activate() {
    this.paorList = await this.client.publicAccountOrders();
  }
}
