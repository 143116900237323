import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MyHttpApi, GdprDataResponse, GdprOrder } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class GdprRequest {
  private data?: GdprDataResponse = undefined;
  private jsonDownloadUrl = "";
  private order?: GdprOrder = undefined;

  constructor(private notify: Notify, private api: MyHttpApi, private router: Router, private i18n: I18N) {
  }

  async activate() {
    if (this.api.session) {
      this.jsonDownloadUrl = this.api.gdprDownloadUrl();
      this.data = await this.api.gdprData();
    } else {
      this.notify.info("gdpr.noSession");
      this.router.navigateToRoute("/");
    }
  }

  showOrder(order: GdprOrder) {
    this.order = order;
  }

  async terminate() {
    if (!confirm(this.i18n.tr("gdpr.terminateConfirm", {}))) {
      return;
    }
    await this.api.actorTerminate();
    await this.api.sessionLogout();
    this.api.session = undefined;
    this.router.navigateToRoute("/");
  }
}
