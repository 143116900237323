export default {
  AddressType: {
    HOME: "Hem",
    WORK: "Arbete",
    OTHER: "Annan"
  },
  AuthenticationType: {
    EMAIL: "e-post",
    PHONE: "SMS",
  },
  DayOfWeek: {
    MONDAY: "Mondag",
    TUESDAY: "Tisdag",
    WEDNESDAY: "Onsdag",
    THURSDAY: "Torsdag",
    FRIDAY: "Fredag",
    SATURDAY: "Lördag",
    SUNDAY: "Söndag",
  },
  DayOfWeekShort: {
    MONDAY: "Mon",
    TUESDAY: "Tue",
    WEDNESDAY: "Wed",
    THURSDAY: "Thu",
    FRIDAY: "Fri",
    SATURDAY: "Sat",
    SUNDAY: "Sun",
  },
  DeliveryMethod: {
    EMAIL: "e-post",
    FETCH: "Hämta",
    ON_SITE: "På plats",
  },
  HumanOrderStatus: {
    NEW: "Beställning betald framgångsrikt. Tack för din beställning.",
    AT_PREMISE: "Vi har tagit emot din beställning och vår personal kommer att leverera den till dig så snart som möjligt.",
    ACCEPTED: "Beställningen har godkänts",
    PAYMENT: "Beställning kräver betalning i förskott",
    PAYMENT_IN_PROCESS: "Betalning bearbetning",
    PREPARED: "Beställning förbereds",
    PICKUP: "Beställningen är klar för avhämtning",
    DELIVERED: "Beställningen har levererats",
    DELETED: "Beställningen har annullerats"
  },
  Language: {
    FI: "Suomi",
    SV: "Svenska",
    EN: "English",
  },
  OrderStatus: {
    NEW: "Ny",
    ACCEPTED: "Väntar på bekräftelse",
    PAYMENT: "Väntar på utbetalning",
    PAYMENT_IN_PROCESS: "Betalning bearbetning",
    PREPARED: "Vara förberedd",
    PICKUP: "Redo för upphämtning",
    DELIVERED: "Beställning är gjord",
    DELETED: "Beställningen har annullerats"
  },
  PaymentRefundStatus: {
    Success: "Betalningen har återbetalats.",
    Failed: "Betalningen har inte återbetalats. Kontakta butiken!",
  },
  PaymentType: {
    OnSite: "Betala vid hämtning",
    PrePaid: "Förbetald beställning",
  },
  WebPaymentType: {
    BLUE_COMMERCE: "Betalning via Verifone",
    VIVA_WALLET: "Betalning via Viva wallet"
  },
  account: {
    code: "Kod som du fått",
    confirm: "Bekräfta",
  },
  address: {
    address: "Adress",
    city: "Stad",
    custom: "Alternativ leveransadress",
    deliveryAddress: "Leveransadress",
    pickupAddress: "Ta upp adress",
    gateCode: "Dörrkod",
    home: "Hem",
    invalidDeliveryAddress: "Ogiltig leveransadress",
    name: "Namn",
    name2: "Namn 2:a raden",
    new: "Lägg till ny adress",
    streetAddress: "Gatuadress",
    type: "Tyep",
    zip: "Postnummer",
  },
  businessUnit: {
    addressName: "Adress",
    unitOfficialIdentifier: "FO-nummer)",
    unitOfficialName: "Företagets officiella namn",
  },
  common: {
    and: "och",
    authenticationType: "Autentiseringstyp",
    back: "Tillbaka",
    clear: "Klar",
    continue: "Fortsätta",
    copy: "Kopiera",
    delete: "Radera",
    email: "e-post",
    empty: "Inget val",
    firstName: "Förnamn",
    language: "Språk",
    lastName: "Efternamn",
    modify: "Ändra",
    name: "Namn",
    notSame: "Lösenorden stämmer inte överens",
    ok: "Ok",
    oldPassword: "Gammalt lösenord",
    optional: "Frivillig",
    password: "Lösenord",
    password2: "Lösenord (igen)",
    phone: "Telfonnummer",
    required: "Nödvändig",
    save: "Spara",
    saved: "Sparat",
    searchButton: "Sök",
    select: "Välj",
    showAll: "Visa allt",
    status: "Status",
    time: "Tid",
  },
  error: {
    extra: {
      invalidation: "Extra val är inte giltiga",
      mandatory: "Minst ett extra urval är obligatoriskt",
    }
  },
  extra: {
    allSelected: "All val som gjorts",
    selectMore: "Välj {{amount}} till",
  },
  forgotPassword: {
    body: "Ange din e-postadress eller ditt telefonnummer. Vi skickar ett meddelande till dig för att återställa ditt lösenord.",
    closeBody: "Du kan nu stänga den här fliken.",
    closeHeading: "lösenord har skickats",
    emailSent: "Meddelande har skickats till angiven adress.",
    noSuchUser: "Ingen sådan användare.",
    title: "Återställ ditt lösenord",
  },
  gdpr: {
    cardCountryCode: "Kortets landskod",
    cardIssuingBank: "kortutgivande bank",
    cardNumber: "Bankkort",
    cardUniqueReference: "Kort unikt id",
    download: "Ladda information i json-format",
    emailSent: "Informationsbegäran har skickats.",
    noMessageOrsession: "Kan inte ladda information. Kolla länken.",
    sendRequest: "Skicka informationsbegäran",
    sessionCreateTime: "Inloggningstid",
    splash: "Begär en länk till dina uppgifter med den e-postadress som användes för att registrera dig för tjänsten.",
    storeName: "Affärsnamn",
    terminate: "Avsluta kunderskapet",
    terminateConfirm: "Är du säker på att du vill avsluta kunderskapet? Efter uppsägning kan du inte komma åt några beställningar du har gjort.",
    transactionType: "Transactionstyp",
    vivaWalletInfos: "Information om betalningstransaktioner",
  },
  giftCard: {
    title: "Presentkortsbeställning",
  },
  google: {
    analyticsText: "Vi använder cookies för att spåra kvaliteten på användarupplevelsen. Om du tillåter användning av cookies, klicka på 'Ja'",
    yes: "Ja",
    no: "Nej",
  },
  login: {
    emailOrPhone: "e-post eller telefonnummer",
    emailOrPhonePlaceholder: "namn@aktiebolag.fi tai 04012345678",
    forgotPassword: "Glomde mitt lösenord...",
    login: "Logga in",
    title: "Logga in TuloPOS till webbshop",
  },
  menu: {
    accountEdit: "Min information",
    gdpr: "Begäran om GDPR-information",
    login: "Logga in",
    logout: "Logga ut",
    orders: "Beställningar",
    storeList: "Butik",
  },
  order: {
    amount: "Belopp",
    backToStore: "Gå tillbaka till butiken",
    closed: "Stängd",
    deliveryDate: "Leveransdatum",
    deliveryTime: "Leveranstid",
    deliveryTimeNoLongerValid: "Beställning kan inte längre göras för den leveranstid du valt",
    deliveryMethod: "Leverans metod",
    desiredReadyTime: "Begärd tid",
    discountCode: "Rabattkod",
    discountEmail: "Din e-postadress",
    discountHeader: "Ange din e-postadress eller rabattkod för dina rabatter.",
    discountGroupName: "Din rabattgrupp {{discountGroup}}",
    downloadInvoice: "Ladda ner faktura",
    extras: "Ytterligare alternativ",
    freeText: "Meddelande till butiken",
    mandatoryPrivacy: "Jag har läst och accepterar",
    mandatoryTerms: "Jag har läst och accepterar villkor för beställning",
    minimumOrderAmount: "Minsta beställning är: {{amount}} €",
    order: "Beställning",
    open: "Öppna",
    outOfStock: "Tillfälligt slut",
    paid: "Betalas kl",
    pay: "Betalat för beställning",
    payment: "Betalning",
		privacyPolicy: "sekretesspolicyn",
    sendOrder: "Beställa",
    restoreOrder: "Din beställning i vår webbutik är ofullständig. Vill du slutföra den.",
    selectDeliverAddress: "Välj leveransadress",
    selectDeliveryDate: "Välj leveranstid",
    selectedDeliverAddress: "Välj leveransadress",
    shopIsClosed: "Butiken är stängd.",
    shopIsClosedButOpensInFuture: "Butiken är stängd nu, men du kan beställa andra dagar genom att välja ett annat datum och tid.",
    shopIsClosedByDefaultAndNoDeliveryDate: "Butiken är endast öppen under särskilda tillfällen. Det finns inga tillfällen inom en snar framtid.",
    shopIsClosedOnSite: "Butiken är öppen när restaurangen är öppen.",
    shopIsClosedTemporarily: "Vi har tillfälligt fått stänga vår nätbutik. Du kan beställa andra dagar genom att välja ett annat datum och tid.",
    tableNumber: "Bord",
    tableNumberHelp: "Var vänlig och ange ditt bords nummer så att personalen kan leverera din beställning till detta bord.",
    total: "Total",
    vat: "MOMS",
    vatPercent: "MOMS-%",
    zeroVatTotal: "Total (MOMS 0%)",
  },
  register: {
    passwordMismatch: "Lösenorden matchar inte",
    passwordPlaceholder: "••••••••",
  },
  server: {
    blueCommerceDown: "Det gick inte att kontakta betalningsbehandlaren. Försök igen om ett ögonblick.",
    emailExists: "Kontot har redan registrerats. Av säkerhetsskäl skickade vi ett meddelande där vi rekommenderar att sätta ett nytt lösenord och logga in med det.",
    gdprByMessageTokenInvalid: "Länken är antingen upphörd eller felaktig",
    invalidUsernameOrPassword: "Fel användarnamn eller lösenord",
    outOfStock: "Produkt eller extra val är inte tillgängligt.",
    passwordNotComplexEnough: "Lösenordet är inte tillräckligt komplext. Lösenordet måste vara minst 8 tecken långt och måste ha minst en liten bokstav och en stor bokstav.",
    wrongLogin: "Kontot har inte åtkomst",
  },
  store: {
    addMoreProduct: "+",
    addProduct: "Lägg till",
    categorySearch: "Sök produkter efter kategori",
    changePortion: "Ändra portionsstorlek",
    copyProduct: "Kopiera produkt (för annat urval)",
    closed: "Stängd",
    discountGroupsFound: "Rabatt aktiverad.",
    discountGroupsNotFound: "Rabattkoden hittades inte",
    discountPercent: "Rabatt",
    fetchAddress: "Hämtä adress",
    fullPrice: "Fullt pris",
    noProductsFound: "Inga produkter hittades med sökning “{{search}}”.",
    outOfInventory: "Det finns bara {{quantity}} stycken kvar av produkten",
    paymentFailure: "Betalningstransaktionen misslyckades",
    paymentFailureExplanation: "Din betalningstransaktion misslyckades, du kan försöka betala igen från knappen nedan.",
    price: "Pris",
    productCategoryCanNotBeSold: "Produktkategori är inte tillgänglig under den valda tiden",
    productCanBeSoldInFuture: "Produkten blir tillgänglig efter {{startDate}}. Välj datum från ovan.",
    productCanNotBeSold: "Produkten är inte tillgänglig under den valda tiden",
    productCategorySalePeriod: "Försäljningsperiod",
    productNotInSale: "Produkten kan inte säljas",
    productSearch: "Sök efter produkter efter namn",
    productSearchInputPlaceholder: "Sök produkter",
    productsLeft: " kvar!",
    removeProduct: "\u2212",
    search: "Sök",
    storeIsClosed: "Butiken är stängd",
    quantity: "Belopp",
    unitPrice: "Enhetspris",
    value: "Värde (€)",
  },
};
